import { setState } from "@dp-core/state3/fun/setState";
import { useStateValue } from "@dp-core/state3/fun/useStateValue";
import { STreeIn, STreeOut } from "@dp-core/state3/type";
import { StrEx } from "@dp-core/utils/StrEx";

export function useStateTree(tree: STreeIn): STreeOut {
	const _tree = {} as STreeOut;
	const isFlatState = Object.values(tree).every((value) => typeof value !== "object" || value === null);
	const normalizedTree = isFlatState ? { local: tree } : tree;
	const entries = Object.entries(normalizedTree);

	entries.forEach(([group, states]) => {
		_tree[group] = {};

		if (!states) {
			return;
		}

		const stateEntries = Object.entries(states);

		stateEntries.forEach((conf) => {
			const isNumeric = StrEx.isNumeric(conf[0]);
			const state = isNumeric ? conf[1] : conf[0];
			const defaultValue = isNumeric ? undefined : conf[1];
			const val = useStateValue(group, state, defaultValue);
			const set = (value: any) => setState(`${group}.${state}`, value);

			_tree[group][state] = { val, set };
		});
	});

	return _tree;
}
