export const findNearestItemFromIndex = (
	items: unknown[],
	filterFn: (item: unknown) => boolean,
	index: number,
	direction: "before" | "after",
): unknown | null => {
	if (direction === "before") {
		for (let i = index - 1; i >= 0; i--) {
			if (filterFn(items[i])) {
				return items[i];
			}
		}
	} else {
		for (let i = index + 1; i < items.length; i++) {
			if (filterFn(items[i])) {
				return items[i];
			}
		}
	}
	return null;
};
