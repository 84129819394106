import { isDebug } from "@dp-core/types/DPProvider";
import { COLORS, createFrame, stringifyMessage } from "./consoleOutput";

// config
// -----------------------------------------------------------------------s-------------------
const errorConfig = {
	color: COLORS.darkPink,
	char: "═",
	maxWidth: 100,
};

export const debugErrorConfig = {
	throwOnError: false,
};

// create framed error
// ------------------------------------------------------------------------------------------
const createFramedError = (errorMessage: string): string => {
	const lines = errorMessage.split("\n");
	return createFrame([...lines], errorConfig);
};

// error function
// ------------------------------------------------------------------------------------------
export const debugError = (
	conditionToThrow: boolean | (() => boolean) | string | undefined,
	...messages: unknown[]
): boolean => {
	// condition
	// ---------
	let _condition = conditionToThrow;
	let _messages = messages;

	// immediately throw error if condition is a string
	// ------------------------------------------------
	if (typeof _condition === "string") {
		_messages = [_condition, ...messages];
		_condition = true;
	}

	// assert messages
	// ---------------
	if (!_messages.length) {
		throw new Error("No messages provided");
	}

	// test condition
	// --------------
	if (!evaluateCondition(_condition)) {
		return false;
	}

	// process messages
	// ----------------
	const processedMessages = processMessages(_messages);
	const framedError = createFramedError(processedMessages);

	// Check if we should throw error
	// -----------------------------------------------
	if (debugErrorConfig.throwOnError) {
		throw new Error(processedMessages);
	}

	// throw error if all conditions pass
	// ----------------------------------
	if (isDebug()) {
		const isFormReject = framedError.includes("Form validation reject");

		if (isFormReject) {
			return true;
		}

		console.error(framedError);

		if (isDebug()) {
			// biome-ignore lint/suspicious/noDebugger: <explanation>+
			debugger;
		} else {
			throw new Error(processedMessages);
		}
	}

	return true;
};

// process messages
// ------------------------------------------------------------------------------------------
const processMessages = (messages: unknown[]) =>
	messages
		.filter((msg): msg is unknown => msg !== undefined)
		.map((msg) => stringifyMessage(msg, errorConfig.maxWidth))
		.join("\n");

// evaluate condition
// ------------------------------------------------------------------------------------------
const evaluateCondition = (conditionToThrow: boolean | (() => boolean) | undefined): boolean =>
	typeof conditionToThrow === "function" ? conditionToThrow() : !!conditionToThrow;
