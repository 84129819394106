import { debugError } from "@dp-core/error/fun/_debugError";
import { S } from "@dp-core/state3/_S";
import { useStore } from "@dp-core/state3/fun/_useStore";
import { Sdot } from "@dp-core/state3/type";

export function appendToState(dot: Sdot, val: any, silent?: boolean): any {
	debugError(!dot?.includes("."), "dot is missing a period (.)", "It is not a valid SDot");

	const store = useStore.getState();
	const currentData = S.val(dot);
	const newData = Array.isArray(currentData) ? [...currentData, val] : { ...currentData, ...val };

	store.setState(dot, newData, silent);

	return newData;
}
