import { DPLang } from "@dp-core/lang/DPLang";
import { DPNumberFormats } from "@dp-core/lang/types";
import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

// get calc
// ---------------------------------------------------------------------------------------------------
export function getCalc(
	dot: Sdot | string | undefined,
	propName: string,
	formula?: string,
	format?: DPNumberFormats,
	decimals: number = 0,
): string | number {
	const val = S.val(dot);
	let _val: number | string = -1;

	if (!formula) {
		if (Array.isArray(val)) {
			_val = val.reduce((acc: number, item: Record<string, number>) => acc - item[propName], 0);
		}
	} else {
		try {
			if (Array.isArray(val)) {
				const variables = val.reduce((acc: Record<string, number>, item: Record<string, number>, index: number) => {
					acc[`${propName}${index}`] = item[propName] || 0;
					return acc;
				}, {});

				_val = evaluateMathExpression(formula, variables);
			} else {
				_val = evaluateMathExpression(formula);
			}
		} catch (error) {
			console.error("Formula evaluation failed:", error);
			_val = -1;
		}
	}

	if (format && typeof _val === "number") {
		_val = DPLang.formatNumber(_val, format, decimals) as string;
	}

	return _val;
}

// sanitize expression
// ---------------------------------------------------------------------------------------------------
function sanitizeExpression(expression: string): string {
	const sanitized = expression.replace(/\s+/g, "");

	if (!/^[-+*/\d.()\w\s]+$/.test(sanitized)) {
		throw new Error("Invalid characters in expression");
	}

	return sanitized;
}

// evaluate math expression
// ---------------------------------------------------------------------------------------------------
function evaluateMathExpression(formula: string, variables: Record<string, number> = {}): number {
	const sanitized = sanitizeExpression(formula);

	const evaluated = Object.entries(variables).reduce((expr, [name, value]) => {
		const regex = new RegExp(`\\b${name}\\b`, "g");
		return expr.replace(regex, value.toString());
	}, sanitized);

	try {
		const evalFn = new Function(`return ${evaluated}`);
		const result = evalFn();

		if (typeof result !== "number" || !Number.isFinite(result)) {
			throw new Error("Invalid result");
		}

		return result;
	} catch (error) {
		throw new Error(`Failed to evaluate expression: ${(error as Error).message}`);
	}
}
