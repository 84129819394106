import { debugError } from "@dp-core/error/fun/_debugError";
import { Sdot, STreeOut } from "@dp-core/state3/type";

// add methods to tree
// ----------------------------------------------------------------------------------------
export function addMethodsToTree(tree: STreeOut) {
	tree.state = (dot: Sdot, throwIfNotExists?: boolean) => {
		const [groupName, stateName] = dot.split(".");
		const group = tree[groupName];
		debugError(throwIfNotExists !== false && !group, `state3 group ${groupName} not found`);
		const _state = group?.[stateName];
		debugError(throwIfNotExists !== false && !_state, `state3 state ${stateName} not found`);
		return _state;
	};

	tree.set = (state: Sdot, value: any) => {
		tree.state(state)?.set(value);
	};

	tree.val = (dot: Sdot, throwIfNotExists?: boolean) => {
		const _dot = dot.replace("{", "").replace("}", "") as Sdot;
		return tree.state(_dot, throwIfNotExists)?.val;
	};

	tree.clear = (groupName: string) => {
		delete tree[groupName];
	};
}
