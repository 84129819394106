export const keyArrayToObject = <T extends string, D extends { key: string }>(
	vars: D[],
	...keysToExtract: T[]
): {
	[K in T]?: D;
} =>
	vars.reduce(
		(acc, v) => {
			if (keysToExtract.includes(v.key as T)) {
				acc[v.key as T] = v;
			}
			return acc;
		},
		{} as {
			[K in T]?: D;
		},
	);
