import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";
import { ArrEx } from "@dp-core/utils/array/ArrEx/_ArrEx";

// get toggle item
// ---------------------------------------------------------------------------------------------------
export function getToggleItem<T>(dot: Sdot | string | undefined, item: T, propName: keyof T = "_id" as keyof T): T[] {
	const old_arr = S.val(dot) || [];
	let new_arr: any[] = [];

	if (Array.isArray(old_arr)) {
		new_arr = ArrEx.toggleItem(old_arr, item, propName);
		S.set(dot as Sdot, new_arr);
	}

	return new_arr;
}
