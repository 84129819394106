export const phrases = {
	_close: "Close",
	_customerId: "Customer ID",
	_id: "ID",
	accept: "Accept",
	acceptDoc: "Waiting for customer to accept or reject",
	acceptDocPublic: "Accept document",
	acceptOrRejectText: "Please accept or reject the document.",
	accountSettings: "Account settings",
	active: "Active",
	activity: "Activity",
	add: "Add",
	addDocumentReviewer: "Add document reviewer",
	addMoreReviewers: "Add more reviewers",
	address: "Address",
	address2: "Address 2",
	addRow: "Add row",
	age: "Age",
	agenda: "Agenda",
	alarms: "Alarms",
	allCustomerHours: "All customer hours",
	allCustomersTime: "All customers",
	allHours: "All hours",
	allInternalTime: "All internal",
	allNonWorkedTime: "All non worked",
	allTime: "Total",
	amount: "Amount",
	amountError: "Amount is not correct",
	analysis: "Analysis",
	analyzeEmail: "Analyze email",
	approve: "Approve",
	areYouSure: "Are you sure?",
	auctionDetails: "Auction details",
	auctions: "Auctions",
	back: "Go back",
	bank: "Bank",
	bankDetails: "Bank details",
	belongsTo: "Belongs to",
	bir_payment_initiated: "BIR payment initiated",
	bookTheseDatesAndTimes: "Book the selected dates",
	calculate: "Calculate",
	cancel: "Cancel",
	capital: "Capital",
	caption: "Caption",
	category: "Category",
	changeOrderStatus: "Change order status",
	changePassword: "Change password",
	channels: "Channels",
	city: "City",
	close: "Close",
	closed: "Closed",
	colors: "Colors",
	comingWeeks: "Coming weeks",
	comments: "Comments",
	company: "Company",
	completed: "Completed",
	completedPayment: "Completed payment",
	confirm: "Confirm",
	confirmInfo: "Confirm the info",
	confirmPassword: "Confirm password",
	context: "Context",
	continueEditing: "Continue editing",
	contractId: "Contract ID",
	contractTerms: "Contract terms",
	correct: "Correct",
	cost: "Cost",
	create: "Create",
	createAnAccount: "Create an account",
	createCalendarEvent: "Create calendar event",
	created: "Created",
	createdBy: "Created by",
	createProject: "Create project",
	createRevision: "Create revision",
	createTransaction: "Create transaction",
	createUser: "Create user",
	createWithEmail: "Create with Email",
	createWorkOrder: "Create work order",
	customer: "Customer",
	customerEmail: "Customer email",
	customerMeetingHours: "Customer meeting hours",
	customerName: "Customer name",
	customerPhone: "Customer phone",
	customerProjectHours: "Customer project hours",
	customers: "Customers",
	dashboard: "Dashboard",
	date: "Date",
	dateAccepted: "Date accepted",
	dateCompleted: "Date completed",
	dateCreated: "Date created",
	dateExpire: "Expire date",
	dateFailed: "Date failed",
	dateModified: "Date modified",
	dateRejected: "Date rejected",
	dateStarted: "Date started",
	day: "Day",
	debited: "Debited",
	delete: "Delete",
	deleted: "Deleted",
	deliverableHours: "Deliverable hours",
	deliverables: "Deliverables",
	delivered: "Delivered",
	deliveredOrders: "Delivered orders",
	deliveryAddress: "Delivery address",
	deliveryFiles: "Delivery files",
	deliveryStatus: "Delivery status",
	departmentHours: "Department hours",
	departments: "Departments",
	description: "Description",
	detail: "entity",
	details: "Detaljer",
	dialogOpen: "Open dialog",
	discard: "Discard",
	discount: "Discount",
	distance: "Distance",
	documentNotCreatedYet: "Document hasn't been created yet",
	documentReviewers: "Document reviewers",
	download: "Download",
	downloadPdf: "Download PDF",
	dueDate: "Due date",
	economy: "Economy",
	edit: "Edit",
	editTreansaction: "Edit transaction",
	email: "Email",
	enabled: "Enabled",
	endDate: "End date",
	enterEmailToCreateAccount: "Enter your email below to create your account",
	enterEmailToLogin: "Enter your email below to login to your account",
	error: "Error",
	errorOccurred: "An error ocurred",
	errorPayment: "Error in payment",
	event: "Event",
	expected: "Expected",
	export: "Export",
	extractedTasks: "Extracted tasks",
	fees: "Fees",
	field: "Field",
	file: "File",
	files: "Files",
	filter: "Filter",
	firstName: "First name",
	forgotPasswordQuestion: "Forgot your password?",
	format: "Format",
	from: "From",
	getReminders: "Get reminders",
	goToCustomer: "Go to customer",
	groups: "Groups",
	heading: "Heading",
	homeArea: "Home area",
	hours: "Hours",
	iconfirm: "I confirm",
	idNumber: "Identification number",
	ids: "Identification numbers",
	idType: "Type of ID",
	import: "Import",
	inactive: "Inactive",
	includeCleaning: "Include cleaning",
	initiated: "Initiated",
	inProgress: "In progress",
	inputProps: "Input properties",
	instance: "Instance",
	interest: "Interest",
	internalHours: "Internal hours",
	introduction: "Introduction",
	invalidAmountFormat: "Amount is not valid",
	invalidDateFormat: "Date is not valid",
	invalidEmailFormat: "Email doesn't have a valid format",
	invalidIntegerFormat: "Integer required",
	invalidNumberFormat: "Number is not valid",
	invalidPersonalNumberFormat: "Personal number is not valid",
	invalidPhoneNumberFormat: "Phone number is not valid",
	invalidSwedishPersonalNumberFormat: "The swedish personal number is not valid",
	invalidUsernameOrPassword: "Invalid username or password",
	isLocalTrip: "Is local trip",
	issueDate: "Date of issue",
	language: "Language",
	lastMonth: "Last month",
	lastName: "Last name",
	lastQuarter: "Last quarter",
	lastTwoMonths: "Last two months",
	lastTwoQuarters: "Last two quarters",
	lastTwoWeeks: "Last two weeks",
	lastWeek: "Last week",
	lastYear: "Last year",
	leadTime: "Lead time",
	linkedInfo: "Linked information",
	list: "List",
	loan: "Loan",
	loans: "Loans",
	locked: "Locked",
	login: "Login",
	loginToYourAccount: "Login to your account",
	logout: "Log out",
	makePayment: "Make payment",
	man: "Man",
	manuallyAcceptDoc: "You can choose to accept or reject the document for the customer, with the buttons below.",
	manuallyAcceptDocPublic: "Please accept or reject the document.",
	mentionPeopleUsingAt: "Mention others with @",
	message: "Message",
	metadata: "Metadata",
	minutes: "Minutes",
	missing: "Missing",
	mobile: "Mobile",
	mobileNumber: "Mobile number",
	mobileNumberInvalid: "Mobile number is not valid",
	modified: "Modified",
	month: "Month",
	moreInfo: "More info",
	movingDate: "Moving date",
	movingFromAddress: "Moving from address",
	movingFromCity: "Moving from city",
	movingFromFloor: "Moving from floor",
	movingFromHasElevator: "Moving from has elevator",
	movingFromPostalCode: "Moving from postal code",
	movingToAddress: "Moving to address",
	movingToCity: "Moving to city",
	movingToFloor: "Moving to floor",
	movingToHasElevator: "Moving to has elevator",
	movingToPostalCode: "Moving to postal code",
	mydetails: "My details",
	myinfo: "My info",
	mysettings: "My settings",
	name: "Name",
	needAvcatervinning: "Need waste management",
	needBoxes: "Need boxes",
	needHelpPacking: "Need help packing",
	needMagasinering: "Need storage",
	new: "New",
	newOrder: "New order",
	newOrders: "New orders",
	newPassword: "New password",
	next: "Next",
	nextWeek: "Next week",
	no: "No",
	noFile: "No file",
	none: "None",
	nonWorkHours: "Non work hours",
	noParameterSelected: "No parameter selected",
	noRows: "No rows found",
	note: "Note",
	notStarted: "Not started",
	numberInvalid: "Number is not valid",
	objectAttributes: "Object attributes",
	objectId: "Object ID",
	objects: "Objects",
	ok: "OK",
	ongoingOrder: "Ongoing order",
	order: "Order",
	orderCreate: "Create order",
	orderCreator: "Order creator",
	orderDelivered: "Order delivered",
	orderDelivery: "Order delivery",
	orderedBy: "Ordered by",
	orderFiles: "Order files",
	orders: "Orders",
	orderStatus: "Order status",
	orderStatusSelectInfo: "If you change order status, the order sender is notified about the change.",
	outputProps: "Output properties",
	overdue: "Overdue",
	overview: "Overview",
	paid: "Paid",
	parseToContent: "Parse to content",
	passedDeadlines: "Passed deadlines",
	passport: "Passport",
	password: "Password",
	payeeError: "Payee is not valid",
	payment: "Payment",
	paymentCompleted: "Payment completed",
	paymentFailed: "Payment failed",
	payments: "Payments",
	people: "People",
	pep: "PEP",
	personalNumber: "Personal number",
	personHours: "Person hours",
	phone: "Phone",
	phoneNumber: "Phone number",
	pleaseEnterAndConfirmNewPassword: "Please enter & confirm your new password",
	pleaseEnterYourEmailWeWillSendEmail:
		"Please enter your email address, and we'll send you a link to reset your password.",
	previous: "Previous",
	price: "Price",
	print: "Print",
	proceed: "Proceed",
	progress: "Progress",
	project: "Project",
	projectDescription: "Project description",
	projectHours: "Project hours",
	projectName: "Project name",
	projectResponsible: "Project responsible",
	projects: "Projects",
	properties: "Properties",
	quantity: "Quantity",
	quoteTerms: "Quote terms",
	read: "Read",
	reject: "Reject",
	reloadPdf: "Reload PDF",
	remainingBacklog: "Remaining/Backlog",
	report: "Report",
	reports: "Reports",
	requestedFormat: "Requested format",
	required: "Required",
	reset: "Reset",
	resetPassword: "Reset Password",
	resetSuccessful: "Reset Successful",
	residentialBlock: "Residential block",
	responsible: "Responsible",
	results: "Results",
	returnDate: "Return date",
	revert: "Revert",
	review: "Review",
	reviewContent: "Review content",
	reviewedAndApproved: "I have reviewed the content and approve it",
	reviewers: "Reviewers",
	reviews: "Reviews",
	revision: "Revision",
	role: "Role",
	rowSum: "Row sum",
	runs: "Runs",
	save: "Save",
	saveAndClose: "Save and close",
	saveDoc: "Save document",
	saveSuccessful: "The save was successful",
	saveToProject: "Save to project",
	search: "Search",
	seconds: "Seconds",
	seeAllHours: "See all hours",
	select: "Select",
	selectCalendarDate: "Select calendar date",
	selectFile: "Select file",
	selectMultiple: "Select one or multiple items",
	selectSingle: "Select an item",
	send: "Send",
	sendEmail: "Send email",
	senderPaymentReference: "Sender payment reference",
	sendingPayment: "Sending payment",
	sendPayment: "Send payment",
	settings: "Settings",
	sex: "Sex",
	singleCustomerHours: "Customer hours",
	singleCustomerTime: "Customer",
	singleDeliverableTime: "Deliverable",
	singleDepartmentTime: "Department",
	singlePeopleHours: "Person hours",
	singlePersonTime: "Person",
	singleProjectHours: "Project hours",
	singleProjectTime: "Project",
	size: "Size",
	source: "Source",
	sources: "Sources",
	ssnError: "SSN is not valid",
	start: "Start",
	startDate: "Start date",
	started: "Started",
	status: "Status",
	stepAccepted: "This step is accepted",
	stepRejected: "This step is rejected",
	steps: "Steps",
	stop: "Stop",
	storeId: "Store ID",
	subject: "Subject",
	submit: "Submit",
	sureApprove: "Are you sure you want to approve?",
	sureReject: "Are you sure you want to reject?",
	sureToDelete: "Are you sure you want to delete?",
	taxDeduction: "Tax deduction",
	taxDeductionAmount: "Tax deduction amount",
	thisMonth: "This month",
	thisQuarter: "This quarter",
	thisWeek: "This week",
	thisYear: "This year",
	time: "Time",
	timeReport: "Time report",
	timestamp: "Timestamp",
	title: "Title",
	today: "Today",
	totalSum: "Total sum",
	totalSumExcludingVAT: "Total sum (excl VAT)",
	totalSumIncludingVAT: "Total sum (incl VAT)",
	transactions: "Transactions",
	type: "Type",
	types: "Types",
	uiComponent: "UI component",
	uiContext: "UI context",
	undefined: "Undefined",
	unlocked: "Unlocked",
	unsavedChangesClose: "You have unsaved changes, are you sure you want to close?",
	upload: "Upload",
	uploadImage: "Upload image",
	userEdit: "Edit user",
	users: "Users",
	value: "Value",
	variants: "Variants",
	vat: "VAT",
	vatAmount: "VAT amount",
	verifyYourEmail: "Verify Your Email",
	version: "Version",
	week: "Week",
	weight: "Weight",
	weSentYouCodeToVerifyEmail:
		"We sent you a 6 digit code to verify your email address (youremail@gmail.com). Enter in the field below.",
	woman: "Woman",
	write: "Write",
	wrongResult: "Wrong result",
	yes: "Yes",
	youCanLoginWithYourNewPassword: "You can now login with your new password.",
	zip: "Zip code",
	total: "Total",
};
