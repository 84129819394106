import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

// get join
// ---------------------------------------------------------------------------------------------------
export function getJoin(dot: Sdot | string | undefined, propName: string, separator: string = ", "): string {
	const val = S.val(dot);
	let _val: string = "";

	if (Array.isArray(val)) {
		try {
			_val = val
				.map((item) => item[propName] || "")
				.filter(Boolean)
				.join(separator);
		} catch (error) {
			console.error("Join operation failed:", error);
			_val = "";
		}
	}

	return _val;
}
