export class StrEx {
	static randomEnumValue<T>(enumeration: Record<string, T>): T {
		const values = Object.keys(enumeration);
		const enumKey = values[Math.floor(Math.random() * values.length)];
		return enumeration[enumKey] as T;
	}

	static toCamelCase = (input: string, separators: string[] = [" ", "-", "_"]): string => {
		const regex = new RegExp(`[${separators.join("")}]`, "g");
		return input
			.toLowerCase()
			.split(regex)
			.reduce((result, word, index) => result + (index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word), "");
	};

	static camelCaseToDashCase(str: string): string {
		if (str) {
			return str
				.replace(/([A-Z])/g, "-$1") // Add a dash before each uppercase letter
				.toLowerCase() // Convert the entire string to lowercase
				.replace(/^-/, ""); // Remove the dash at the beginning if it exists
		}

		return str;
	}

	static camelCaseToTitleCase(str: string): string {
		const result = str.replace(/([A-Z])/g, " $1").trim();
		return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
	}

	static dashCaseToCamelCase(str: string): string {
		return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
	}

	static captializeFirstChar(str: string): string {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	static isEmptyString(str: string | undefined): boolean {
		return str === "" || str === undefined || str === null || `${str}`?.trim() === "";
	}

	static isArrayOfStrings = (arr: unknown): arr is string[] => {
		if (!Array.isArray(arr)) {
			return false;
		}
		return arr.every((item) => typeof item === "string");
	};

	static isString = (value: unknown): value is string => typeof value === "string";

	static isStringOrArrayOfStrings = (value: unknown): value is string | string[] =>
		StrEx.isString(value) || StrEx.isArrayOfStrings(value);

	static getStringAfterLastChar = (input: string, char: string): string => {
		const lastCharIndex = input.lastIndexOf(char);
		return lastCharIndex === -1 ? input : input.slice(lastCharIndex + 1);
	};

	static isNumeric = (str: string): boolean => {
		if (!str?.length) {
			return false;
		}
		const isNumeric = /^[0-9]+$/.test(str);
		return isNumeric;
	};

	static splitOnce = (str: string, delimiter: string): [string, string] => {
		const index = str.indexOf(delimiter);
		return index === -1 ? [str, ""] : [str.slice(0, index), str.slice(index + delimiter.length)];
	};

	static removeChars = (str: string, charsToRemove: string): string => {
		const removeSet = new Set(charsToRemove);
		let result = "";
		for (let i = 0; i < str.length; i++) {
			if (!removeSet.has(str[i])) {
				result += str[i];
			}
		}
		return result;
	};
}
