import { DPApps } from "@src/apps/apps.types";
import { lazy } from "react";
import { AppToInitialize } from "./app.util";
import "antd4/dist/antd.css";
import "./app.scss";
import DP2Router from "./DP2Apps/DP2Router";

if (AppToInitialize === DPApps.p2) {
  import("./p2/p2.scss");
}
if (AppToInitialize === DPApps.flows) {
  import("./flows/flows.scss");
}

const MRM = lazy(() => import("../router"));
const P2Router = lazy(() => import("./p2/router"));
const FlowRouter = lazy(() => import("./flows/router"));
const TemplateRouter = lazy(() => import("./template/router"));

const DP2Apps = lazy(() => import('./DP2Apps/DP2Apps'));


const DPRouter = ({ history }) => {
  switch (AppToInitialize) {
    case DPApps.p2: {
      return <P2Router history={history} />;
    }
    case DPApps.mrm: {
      return <MRM history={history} />;
    }
    case DPApps.flows: {
      return <FlowRouter history={history} />;
    }
    case DPApps.template: {
      return <TemplateRouter history={history} />;
    }
    case DPApps.pawn_shop: {
      return <DP2Apps app={AppToInitialize} />;
    }
    default: {
      return <DP2Router app={AppToInitialize as DPApps} history={history} />;
    }
  }
};

export default DPRouter;
