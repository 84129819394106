import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

// get map
// ---------------------------------------------------------------------------------------------------
export function getMap(dot: Sdot | string | undefined, propName: string, mapFn?: (value: string) => string): string {
	const val = S.val(dot);
	let _val: string = "";

	if (Array.isArray(val)) {
		if (mapFn) {
			try {
				const mappedValues = val.map((item) => {
					const itemValue = item[propName] || "";
					return mapFn(itemValue);
				});

				_val = mappedValues.join("");
			} catch (error) {
				console.error("Mapping function failed:", error);
				_val = "";
			}
		} else {
			// If no mapping function provided, just join the values
			_val = val.map((item) => item[propName] || "").join("");
		}
	}

	return _val;
}
