export const phrases = {
	_close: "Stäng",
	_customerId: "Kund-ID",
	_id: "ID",
	accept: "Acceptera",
	acceptDoc: "Väntar på att kunden ska acceptera eller avslå",
	acceptDocPublic: "Acceptera dokument",
	acceptOrRejectText: "Vänligen acceptera eller avvisa dokumentet.",
	accountSettings: "Kontoinställningar",
	active: "Aktiv",
	activity: "Aktivitet",
	add: "Lägg till",
	addDocumentReviewer: "Lägg till dokumentgranskare",
	addMoreReviewers: "Lägg till fler granskare",
	address: "Adress",
	address2: "Adress 2",
	addRow: "Lägg till rad",
	age: "Ålder",
	agenda: "Agenda",
	alarms: "Larm",
	allCustomersTime: "All kundtid",
	allInternalTime: "All interntid",
	allNonWorkedTime: "All ej arbetad tid",
	allTime: "All tid",
	amount: "Belopp",
	amountError: "Beloppet är inte korrekt",
	analysis: "Analys",
	analyzeEmail: "Analysera e-post",
	approve: "Godkänn",
	areYouSure: "Är du säker?",
	auctions: "Auktioner",
	back: "Gå tillbaka",
	bank: "Bank",
	bankDetails: "Bankuppgifter",
	belongsTo: "Tillhör",
	bir_payment_initiated: "BIR-betalning initierad",
	bookTheseDatesAndTimes: "Boka de valda datumen",
	cancel: "Avbryt",
	capital: "Kapital",
	caption: "Bildtext",
	category: "Kategori",
	changePassword: "Ändra lösenord",
	channels: "Kanal",
	city: "Stad",
	close: "Stäng",
	closed: "Stängd",
	colors: "Färger",
	comments: "Kommentarer",
	company: "Företag",
	completed: "Avslutad",
	completedPayment: "Genomförd betalning",
	confirm: "Bekräfta",
	confirmInfo: "Bekräfta informationen",
	confirmPassword: "Bekräfta lösenord",
	context: "Kontext",
	continueEditing: "Fortsätt redigera",
	contractId: "Kontrakts-ID",
	contractTerms: "Avtalsvillkor",
	cost: "Kostnad",
	create: "Skapa",
	createCalendarEvent: "Skapa kalenderevent",
	created: "Skapad",
	createdBy: "Skapad av",
	createOrder: "Skapa beställning",
	createProject: "Skapa projekt",
	createRevision: "Skapa revision",
	createTransaction: "Skapa transaktion",
	createUser: "Skapa användare",
	createWorkOrder: "Skapa arbetsorder",
	customer: "Kund",
	customerEmail: "Kundens e-post",
	customerHours: "Kundtimmar",
	customerInteractions: "Kundinteraktioner",
	customerName: "Kundnamn",
	customerPhone: "Kundtelefon",
	customers: "Kunder",
	dailyStatus: "Daglig status",
	dashboard: "Dashboard",
	date: "Datum",
	dateAccepted: "Datum accepterad",
	dateCompleted: "Datum slutförd",
	dateCreated: "Skapad datum",
	dateFailed: "Datum misslyckad",
	dateModified: "Ändringsdatum",
	dateRejected: "Datum avvisad",
	dateStarted: "Datum startad",
	day: "Dag",
	debited: "Debiterad",
	delete: "Ta bort",
	deleted: "Raderad",
	deliverables: "Leverabler",
	deliveredOrder: "Levererad beställning",
	deliveredOrders: "Levererade beställningar",
	delivery: "Leverans",
	deliveryAddress: "Leveransadress",
	deliveryFiles: "Levererade filer",
	deliveryStatus: "Leveransstatus",
	departments: "Avdelningar",
	description: "Beskrivning",
	detail: "Detalj",
	dialogOpen: "Öppna dialog",
	discard: "Släng",
	discount: "Rabatt",
	distance: "Avstånd",
	document: "Dokument",
	documentNotCreatedYet: "Dokumentet har inte skapats än",
	documentReviewers: "Dokumentgranskare",
	downloadPdf: "Ladda ner PDF",
	driversLicense: "Körkort",
	dueDate: "Förfallodatum",
	economy: "Ekonomi",
	edit: "Redigera",
	editTransaction: "Redigera transaktion",
	email: "E-post",
	emailSent: "E-post skickat",
	enabled: "Aktiverad",
	endDate: "Slutdatum",
	error: "Fel",
	errorOccurred: "Ett fel uppstod",
	errorPayment: "Ett fel uppstod vid betalning",
	event: "Händelse",
	extractedTasks: "Extraherade uppgifter",
	fees: "Avgifter",
	field: "Fält",
	file: "Fil",
	files: "Filer",
	filter: "Filtrera",
	financialDetails: "Ekonomiska detaljer",
	firstName: "Förnamn",
	format: "Format",
	from: "Från",
	getReminders: "Få påminnelser",
	goToCustomer: "Gå till kund",
	heading: "Rubrik",
	homeArea: "Bostadsyta",
	hours: "Timmar",
	iconfirm: "Jag bekräftar",
	idCard: "Identitetskort",
	identification: "Identifiering",
	idNumber: "Identifikationsnummer",
	idType: "Typ av ID",
	inactive: "Inaktiv",
	includeCleaning: "Inkludera städning",
	initiated: "Initierad",
	inProgress: "Pågående",
	inputProps: "Indata",
	instance: "Instans",
	interest: "Ränta",
	invalidAmountFormat: "Beloppet har inte ett giltigt format",
	invalidDateFormat: "Datumet har inte ett giltigt format",
	invalidEmailFormat: "E-postadressen har inte ett giltigt format",
	invalidNumberFormat: "Numret har inte ett giltigt format",
	invalidPersonalNumberFormat: "Personnumret har inte ett giltigt format",
	invalidPhoneNumberFormat: "Telefonnumret har inte ett giltigt format",
	invalidSwedishPersonalNumberFormat: "Personnumret är inte giltigt",
	isLocalTrip: "Är det en lokal flytt",
	issueDate: "Utfärdandedatum",
	language: "Språk",
	lastMonth: "Förra månaden",
	lastName: "Efternamn",
	lastQuarter: "Förra kvartalet",
	lastTwoMonths: "Senaste två månaderna",
	lastTwoQuarters: "Senaste två kvartalen",
	lastTwoWeeks: "Senaste två veckorna",
	lastWeek: "Förra veckan",
	lastYear: "Förra året",
	leadTime: "Ledtid",
	linkedInfo: "Länkad information",
	list: "Lista",
	loan: "Lån",
	loanDetails: "Lånedetaljer",
	loans: "Lån",
	loanStatus: "Lånestatus",
	locked: "Låst",
	logout: "Logga ut",
	makePayment: "Gör utbetalning",
	man: "Man",
	manuallyAcceptDoc: "Du kan välja att acceptera eller avvisa dokumentet åt kunden med knapparna nedan.",
	manuallyAcceptDocPublic: "Vänligen acceptera eller avvisa dokumentet.",
	mentionPeopleUsingAt: "Tagga andra med @",
	message: "Meddelande",
	metadata: "Metadata",
	minutes: "Minuter",
	missing: "Saknas",
	mobile: "Mobil",
	mobileNumber: "Mobilnummer",
	mobileNumberInvalid: "Mobilnumret är inte giltigt",
	modified: "Ändrad",
	month: "Månad",
	moreInfo: "Mer information",
	movingDate: "Flyttdatum",
	movingFromAddress: "Flyttar från adress",
	movingFromCity: "Flyttar från stad",
	movingFromFloor: "Flyttar från våning",
	movingFromHasElevator: "Har hiss vid nuvarande adress",
	movingFromPostalCode: "Flyttar från postnummer",
	movingToAddress: "Flyttar till adress",
	movingToCity: "Flyttar till stad",
	movingToFloor: "Flyttar till våning",
	movingToHasElevator: "Har hiss vid nya adressen",
	movingToPostalCode: "Flyttar till postnummer",
	mydetails: "Mina uppgifter",
	myinfo: "Mina uppgifter",
	mysettings: "Min inställningar",
	name: "Namn",
	needAvcatervinning: "Behöver avfallshantering",
	needBoxes: "Behöver lådor",
	needHelpPacking: "Behöver hjälp med packning",
	needMagasinering: "Behöver magasinering",
	new: "Ny",
	newOrder: "Ny beställning",
	newOrders: "Nya beställningar",
	newPassword: "Nytt lösenord",
	next: "Nästa",
	no: "Nej",
	none: "Ingen",
	noParameterSelected: "Inga parameterar valda",
	noRows: "Inga rader att visa",
	note: "Anteckning",
	notStarted: "Ej påbörjad",
	numberInvalid: "Numret är inte giltigt",
	objectAttributes: "Objektattribut",
	objects: "Objekt",
	ok: "OK",
	ongoing: "Pågående beställning",
	order: "Beställning",
	orderCreator: "Beställare",
	orderDelivered: "Beställning levererad",
	orderedBy: "Beställare",
	orders: "Beställningar",
	orderStatus: "Beställningsstatus",
	outputProps: "Utdata",
	overdue: "Försenad",
	paid: "Betald",
	parseToContent: "Tolka till innehåll",
	passport: "Pass",
	payeeError: "Betalningssmottagaren är inte giltig",
	payment: "Betalning",
	paymentCompleted: "Betalning genomförd",
	paymentFailed: "Betalningen misslyckades",
	payments: "Betalningar",
	people: "Personer",
	pep: "PEP",
	personalInfo: "Personlig information",
	personalNumber: "Personnummer",
	personHours: "Persontimmar",
	phone: "Telefon",
	phoneNumber: "Telefonnummer",
	previous: "Föregående",
	price: "Pris",
	print: "Skriv ut",
	proceed: "Fortsätt",
	progress: "Progress",
	project: "Projekt",
	projectDescription: "Projektbeskrivning",
	projectName: "Projektnamn",
	projectResponsible: "Projektansvarig",
	projects: "Projekt",
	properties: "Egenskaper",
	quantity: "Antal",
	quoteTerms: "Offertvillkor",
	reject: "Avslå",
	reloadPdf: "Ladda om PDF",
	report: "Rapport",
	reports: "Rapporter",
	requestedFormat: "Önskat format",
	required: "Obligatorisk",
	residentialBlock: "Bostadskvarter",
	responsible: "Ansvarig",
	results: "Resultat",
	returnDate: "Återlämningsdatum",
	revert: "Återställ",
	review: "Granskning",
	reviewContent: "Granska innehåll",
	reviewedAndApproved: "Godkänn och maila offerten till kund",
	reviewers: "Granskare",
	reviews: "Granskningar",
	revision: "Revision",
	role: "Roll",
	rowSum: "Radsumma",
	runs: "Körningar",
	save: "Spara",
	saveAndClose: "Spara och stäng",
	saveDoc: "Spara dokument",
	saveToProject: "Spara till projekt",
	search: "Sök",
	seconds: "Sekunder",
	seeAllHours: "Se alla timmar",
	select: "Välj",
	selectCalendarDate: "Välj kalenderdatum",
	selectInfo: "Om du ändrar beställningens status, meddelas beställaren om ändringen.",
	selectMultiple: "Välj en eller flera",
	selectSingle: "Välj en",
	send: "Skicka",
	sendEmail: "Skicka e-post",
	senderPaymentReference: "Avsändarens betalningsreferens",
	sendingPayment: "Skickar betalning",
	sendPayment: "Skicka betalning",
	settings: "Inställningar",
	sex: "Kön",
	singleCustomerTime: "Kundtid",
	singleDeliverableTime: "Leverabeltid",
	singleDepartmentTime: "Avdelningstid",
	singlePersonTime: "Persontid",
	singleProjectHours: "Projekttid",
	singleProjectTime: "Projekttid",
	size: "Storlek",
	smsSent: "Sms skickat",
	source: "Källa",
	sources: "Källor",
	ssnError: "Personnummer är inte giltigt",
	start: "Start",
	startDate: "Startdatum",
	started: "Påbörjad",
	status: "Status",
	statusSelect: "Ändra beställningens status",
	stepAccepted: "Detta steg är accepterat",
	stepRejected: "Detta steg är avvisat",
	steps: "Steg",
	stop: "Stopp",
	subject: "Ämne",
	sureApprove: "Är du säker på att du vill acceptera?",
	sureReject: "Är du säker på att du vill avslå?",
	sureToDelete: "Är du säker på att du vill ta bort?",
	taxDeduction: "Skatteavdrag",
	taxDeductionAmount: "Skatteavdrag belopp",
	thisMonth: "Denna månad",
	thisQuarter: "Detta kvartal",
	thisWeek: "Denna vecka",
	thisYear: "Detta år",
	time: "Tid",
	timeReport: "Tidrapport",
	timestamp: "Tidsstämpel",
	title: "Titel",
	today: "Idag",
	totalSum: "Totalsumma",
	totalSumExcludingVAT: "Totalsumma (exkl moms)",
	totalSumIncludingVAT: "Totalsumma (inkl moms)",
	transaction: "Transaktion",
	transactions: "Transaktioner",
	type: "Typ",
	types: "Types",
	uiComponent: "UI-komponent",
	uiContext: "UI-kontext",
	undefined: "Odefinierad",
	unlocked: "Upplåst",
	unsavedChangesClose: "Du har osparade ändringar, är du säker på att du vill stänga?",
	upload: "Ladda upp",
	userEdit: "Redigera användare",
	users: "Användare",
	value: "Värde",
	variants: "Varianter",
	vat: "Moms",
	vatAmount: "Momsbelopp",
	verification: "Verifiering",
	version: "Version",
	week: "Vecka",
	weight: "Vikt",
	woman: "Kvinna",
	yes: "Ja",
	zip: "Postnummer",
	details: "Detaljer",
	correct: "Korrekt",
	wrongResult: "Fel resultat",
	expected: "Förväntat",
	introduction: "Introduktion",
	uploadImage: "Ladda upp bild",
	noFile: "Ingen fil",
	selectFile: "Välj fil",
	invalidIntegerFormat: "Heltal krävs",
	forgotPasswordQuestion: "Glömt lösenordet?",
	submit: "Skicka",
	pleaseEnterYourEmailWeWillSendEmail:
		"Vänligen ange din e-postadress, så skickar vi dig en länk för att återställa ditt lösenord.",
	loginToYourAccount: "Logga in på ditt konto",
	enterEmailToLogin: "Ange din e-postadress nedan för att logga in på ditt konto",
	createAnAccount: "Skapa ett konto",
	enterEmailToCreateAccount: "Ange din e-postadress nedan för att skapa ditt konto",
	createWithEmail: "Skapa med e-post",
	resetPassword: "Återställ lösenord",
	pleaseEnterAndConfirmNewPassword: "Vänligen ange och bekräfta ditt nya lösenord",
	reset: "Återställ",
	resetSuccessful: "Återställning lyckades",
	youCanLoginWithYourNewPassword: "Du kan nu logga in med ditt nya lösenord.",
	verifyYourEmail: "Verifiera din e-post",
	weSentYouCodeToVerifyEmail:
		"Vi har skickat en 6-siffrig kod för att verifiera din e-postadress (youremail@gmail.com). Ange den i fältet nedan.",
	saveSuccessful: "Spara lyckades",
	dateExpire: "Utgångsdatum",
	overview: "Översikt",
	login: "Logga in",
	password: "Lösenord",
	read: "Läs",
	write: "Skriv",
	download: "Ladda ner",
	export: "Exportera",
	import: "Importera",
	calculate: "Beräkna",
	invalidUsernameOrPassword: "Ogiltigt användarnamn eller lösenord",
	groups: "Grupper",
	total: "Totalt",
};
