import { g_s } from "@dp-core/state3/fun/helper/_dot";
import { getKeyExists } from "@dp-core/state3/fun/keyExists";
import { Sdot, SStore } from "@dp-core/state3/type";
import { create } from "zustand";

// store
// ------------------------------------------------------------------------------------------
export const useStore = create<SStore>((set, get) => ({
	groups: {},

	setState: (dot: Sdot, value: any) => {
		set(setStateFn(dot, value));
	},

	clearState: (groupName: string, stateName: string) => {
		set(clearStateFn(groupName, stateName));
	},

	clearGroup: (groupName: string) => {
		set(clearGroupFn(groupName));
	},

	clearStore: () => {
		set({ groups: {} });
	},

	keyExists: (dot?: Sdot) => getKeyExists(get, dot),
}));

// functions
// ------------------------------------------------------------------------------------------
const setStateFn = (dot: Sdot, value: any) => (store: SStore) => {
	const { groupName, stateName } = g_s(dot);

	return {
		groups: {
			...store.groups,
			[groupName]: {
				...store.groups[groupName],
				[stateName]: value,
			},
		},
	};
};

const clearStateFn = (groupName: string, stateName: string) => (store: SStore) => {
	const updatedGroups = { ...store.groups };

	if (updatedGroups[groupName]) {
		delete updatedGroups[groupName][stateName];
		if (Object.keys(updatedGroups[groupName]).length === 0) {
			delete updatedGroups[groupName];
		}
	}

	return { groups: updatedGroups };
};

const clearGroupFn = (groupName: string) => (state: SStore) => {
	const updatedGroups = { ...state.groups };
	delete updatedGroups[groupName];
	return { groups: updatedGroups };
};
