import { debugError } from "@dp-core/error/fun/_debugError";

export class DPRateLimit {
	private static store_calls: Record<string, number[]> = {};

	static check(key: string, maxCalls: number, timeWindowMs: number = 1000): void {
		const calls = this.store_calls[key];
		const now = Date.now();

		// Fast path: no existing calls
		if (!calls) {
			this.store_calls[key] = [now];
			return;
		}

		// Quick check if we're under limit without cleanup
		if (calls.length < maxCalls && now - calls[0] < timeWindowMs) {
			calls.push(now);
			return;
		}

		// Only clean outdated calls if we're at limit or window expired
		while (calls.length && now - calls[0] >= timeWindowMs) {
			calls.shift();
		}

		if (calls.length >= maxCalls) {
			const waitTime = timeWindowMs - (now - calls[0]);
			debugError(`Rate limit exceeded for ${key}. Called ${calls.length} times. Wait ${waitTime / 1000} sec.`);
			return;
		}

		calls.push(now);
	}

	static reset(key: string): void {
		delete this.store_calls[key];
	}
}
