import { useRef } from "react";
import { S } from "../_S";
import { _dot } from "./helper/_dot";

// state id
// ------------------------------------------------------------------------------------------
let counter = 0;

export const k_id = (prefix: string) => {
	const id = `${prefix}-${++counter}`;
	const _id = useRef(id).current;
	return _id;
};

export const k_dot = (prefix: string, stateName: string) => {
	const id = k_id(prefix);
	const dot = _dot(id, stateName);
	return dot;
};

export const k_val = (prefix: string, stateName: string) => {
	const dot = k_dot(prefix, stateName);
	const val = S.val(dot);
	return val;
};
