export const hasPath = (obj: any, path: string): boolean => {
	if (!obj || typeof path !== "string") {
		return false;
	}

	const keys = path.split(".");
	return (
		keys.reduce((acc, key) => (acc && Object.prototype.hasOwnProperty.call(acc, key) ? acc[key] : undefined), obj) !==
		undefined
	);
};

export const hasPathWithValue = (obj: any, path: string, value: unknown): boolean => {
	if (!obj || typeof path !== "string") {
		return false;
	}

	const keys = path.split(".");
	const result = keys.reduce(
		(acc, key) => (acc && Object.prototype.hasOwnProperty.call(acc, key) ? acc[key] : undefined),
		obj,
	);

	return result === value;
};
