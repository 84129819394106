import { debugError } from "@dp-core/error/fun/_debugError";
import { Sdot } from "@dp-core/state3/type";
import { StrEx } from "@dp-core/utils/StrEx";

export function _dot(groupName: string | undefined, stateName: string): Sdot {
	debugError(!groupName, "groupName is empty");
	debugError(!stateName, "stateName is empty");
	return `${groupName}.${stateName}`;
}

export function g_s(gs: Sdot | string): { groupName: string; stateName: string } {
	debugError(!gs, "gs is empty");
	const str = StrEx.removeChars(gs, "{}");
	const [groupName, stateName] = StrEx.splitOnce(str, ".");
	return { groupName, stateName };
}
