export const deleteArrayItem = <T extends Record<string, any>>(
	arr: T[],
	item: T,
	comparePropertyKey: keyof T = "_id" as keyof T,
): T[] => arr.filter((existingItem) => existingItem[comparePropertyKey] !== item[comparePropertyKey]);

export const arraysEqual = <T>(a?: T[], b?: T[]): boolean => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; i++) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};
