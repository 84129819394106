import { hasPath, hasPathWithValue } from "@dp-core/utils/ObjEx/hasPropWithValue";
import { replaceByKeys } from "@dp-core/utils/ObjEx/replaceByKeys";
import { replaceVal } from "@dp-core/utils/ObjEx/replaceVal";
import { allFieldsAreUndefined } from "./allFieldsAreUndefined";
import { arePropsEqual } from "./arePropsEqual";
import { assertPropVal } from "./assertPropVal";
import { cloneObjectProps } from "./cloneObjectProps";
import { clonePropsAndFn } from "./clonePropsAndFn";
import { copyWithoutGetters } from "./copyWithoutGetters";
import { crawlObjectTree } from "./crawlObjectTree";
import { firstNonEmpty } from "./firstNonEmpty";
import { firstNonNullOrUndefined } from "./firstNonNullOrUndefined";
import { forEachKey } from "./forEachKey";
import { getFirstKey } from "./getFirstKey";
import { getFirstValue } from "./getFirstValue";
import { getFunctionParameters } from "./getFunctionParameters";
import { getKeys } from "./getKeys";
import { getType } from "./getType";
import { getVal } from "./getVal";
import { hasAnyProp } from "./hasAnyProp";
import { hasEveryProp } from "./hasEveryProp";
import { isEmptyObject } from "./isEmptyObject";
import { isEqual_deep } from "./isEqual_deep";
import { isIntegerPropsObject } from "./isIntegerPropsObject";
import { isNotEmptyObject } from "./isNotEmptyObject";
import { isNullOrUndefined } from "./isNullOrUndefined";
import { merge } from "./merge";
import { omit } from "./omit";
import { reduceProps } from "./reduceProps";
import { setVal } from "./setVal";

export class ObjEx {
	static allFieldsAreUndefined = allFieldsAreUndefined;
	static arePropsEqual = arePropsEqual;
	static assertPropVal = assertPropVal;
	static cloneObjectProps = cloneObjectProps;
	static clonePropsAndFn = clonePropsAndFn;
	static copyWithoutGetters = copyWithoutGetters;
	static crawlObjectTree = crawlObjectTree;
	static firstNonEmpty = firstNonEmpty;
	static firstNonNullOrUndefined = firstNonNullOrUndefined;
	static forEachKey = forEachKey;
	static getFirstKey = getFirstKey;
	static getFirstValue = getFirstValue;
	static getFunctionParameters = getFunctionParameters;
	static getKeys = getKeys;
	static getType = getType;
	static getVal = getVal;
	static hasAnyProp = hasAnyProp;
	static hasEveryProp = hasEveryProp;
	static hasPathWithValue = hasPathWithValue;
	static hasPath = hasPath;
	static isEmptyObject = isEmptyObject;
	static isEqual_deep = isEqual_deep;
	static isIntegerPropsObject = isIntegerPropsObject;
	static isNotEmptyObject = isNotEmptyObject;
	static isNullOrUndefined = isNullOrUndefined;
	static merge = merge;
	static replaceByKeys = replaceByKeys;
	static omit = omit;
	static reduceProps = reduceProps;
	static setVal = setVal;
	static replaceVal = replaceVal;
}
