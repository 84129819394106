import { debugError } from "@dp-core/error/fun/_debugError";
import { S } from "@dp-core/state3/_S";
import { _dot, g_s } from "@dp-core/state3/fun/helper/_dot";
import { Sdot } from "@dp-core/state3/type";
import { useStore } from "./_useStore";

export function setState(dot: Sdot, value: any | ((prev: any) => any), silent?: boolean): any {
	const { groupName, stateName } = g_s(dot);

	debugError(!groupName, "groupName is required");
	debugError(!stateName, "stateName is required");

	const store = useStore.getState();
	const new_val = typeof value === "function" ? value(S.val(dot)) : value;

	// state with path
	// --------------
	if (stateName.includes(".")) {
		const [_stateName, path] = stateName.split(".");
		const obj = S.val(`${groupName}.${_stateName}`);

		if (obj) {
			obj[path] = new_val;
			store.setState(`${groupName}.${_stateName}`, { ...obj }, silent);
		}
	}

	// plain state
	// -----------
	else {
		store.setState(dot, new_val, silent);
	}

	return new_val;
}
