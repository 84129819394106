import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";
import { ArrEx } from "@dp-core/utils/array/ArrEx/_ArrEx";

// exists
// ---------------------------------------------------------------------------------------------------
export function getExists<T>(
	dot: Sdot | string | undefined,
	item: T,
	compareProp: keyof T = "_id" as keyof T,
): boolean {
	const arr = S.val(dot);
	const exists = arr && ArrEx.exists(arr, item, compareProp);
	return exists;
}
