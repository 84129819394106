import { g_s } from "@dp-core/state3/fun/helper/_dot";
import { Sdot, SStore } from "@dp-core/state3/type";
import { ObjEx } from "@dp-core/utils/ObjEx/_ObjEx";
import { useStore } from "./_useStore";

export const keyExists = (dot?: Sdot): boolean => {
	return useStore.getState().keyExists(dot);
};

export const getKeyExists = (get: () => SStore, dot?: Sdot): boolean => {
	if (!dot) {
		return false;
	}

	const groups = get().groups;
	const { groupName, stateName } = g_s(dot);
	const exists = ObjEx.hasPath(groups[groupName], stateName);

	return exists;
};
