import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

export function getCount(dot: Sdot | string | undefined, use?: boolean) {
	const val = use ? S.useval(dot) : S.val(dot);
	if (Array.isArray(val)) {
		return val.length;
	}
	return -1;
}
