import { DPLang } from "@dp-core/lang/DPLang";
import { DPFormats } from "@dp-core/lang/types";
import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";
import { ObjEx } from "@dp-core/utils/ObjEx/_ObjEx";

export const useAndReturnVal = (dot: Sdot | string | undefined, defVal?: any, format?: DPFormats) => {
	const parts = dot?.split(".") ?? [];
	const useKey = parts.slice(0, 2).join(".");
	const path = parts.slice(2).join(".") || undefined;
	let val = S.use(useKey as Sdot, defVal)?.val(dot as Sdot);

	if (path) {
		val = ObjEx.getVal(val, path);
	}

	return format ? DPLang.format(val, format) : val;
};
