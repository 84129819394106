import type { DPProps } from "@dp-core/component/type/DPProps";
import React, { type ReactNode } from "react";

export const cloneChildrenRecursively = <P extends DPProps>(children: ReactNode, props: P) => {
	return React.Children.map(children, (child, index) => {
		if (!React.isValidElement(child)) {
			return child;
		}

		const childProps = {
			...child.props,
			...props,
			key: child.key || `key-${index.toString()}`,
		};

		if (child.props.children) {
			childProps.children = cloneChildrenRecursively(child.props.children, props);
		}

		return React.cloneElement(child, childProps);
	});
};
