import { debugError } from "@dp-core/error/fun/_debugError";
import { g_s } from "@dp-core/state3/fun/helper/_dot";
import { addMethodsToTree } from "@dp-core/state3/fun/helper/addMethodsToTree";
import { setState } from "@dp-core/state3/fun/setState";
import { useStateTree } from "@dp-core/state3/fun/useStateTree";
import { useStateValue } from "@dp-core/state3/fun/useStateValue";
import { Sdot, SIn, STreeIn, STreeOut } from "@dp-core/state3/type";
import { DPRateLimit } from "@dp-core/utils/DPRateLimit";
import { ObjEx } from "@dp-core/utils/ObjEx/_ObjEx";

export function useState(config: SIn): STreeOut;
export function useState(dot: Sdot | `${string}.${string}`, defVal?: any): STreeOut;
export function useState(dotOrTree: Sdot | STreeIn, defVal?: any): STreeOut | undefined {
	// rate limit
	// ----------
	DPRateLimit.check("useState", 200, 1000);

	// empty
	// -----
	if (!dotOrTree || ObjEx.isEmptyObject(dotOrTree)) {
		return undefined;
	}

	// tree var
	// --------
	let _s3!: STreeOut;

	// state tree
	// ----------
	if (typeof dotOrTree === "object") {
		_s3 = useStateTree(dotOrTree);
	}

	// group.state
	// -----------
	else if (typeof dotOrTree === "string" && dotOrTree?.includes(".")) {
		const dot = g_s(dotOrTree);
		_s3 = useStateTree({ [dot.groupName]: { [dot.stateName]: defVal } });
	}

	// single state
	// ------------
	else if (typeof dotOrTree === "string") {
		const dot = g_s(dotOrTree);
		debugError(!dot.stateName, "stateName is required for single state subscription");
		const _name = dot.stateName as string;
		const val = useStateValue(dotOrTree, _name, defVal);
		const set = (value: any) => setState(`${dotOrTree}.${_name}`, value);
		_s3 = { [dotOrTree]: { [_name]: { val, set } } } as STreeOut;
	}

	// add methods to tree
	// -------------------
	addMethodsToTree(_s3);

	// return
	// ------
	return _s3;
}
