import { setState } from "@dp-core/state3/fun/setState";
import { STreeIn } from "@dp-core/state3/type";

export function setStateTree(stateTree: STreeIn) {
	Object.entries(stateTree).forEach(([groupName, groupStates]) => {
		Object.entries(groupStates).forEach(([stateName, value]) => {
			setState(`${groupName}.${stateName}`, value);
		});
	});
}
