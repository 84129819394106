import { createContext, ReactNode, useContext } from "react";

// context
// -------------------------------------------------------------------------------------
export const DPkContext = createContext<DPkContextType>(undefined);
type DPkContextType = string | undefined;

// provider
// -------------------------------------------------------------------------------------
export const DPk = (props: { k: string, children: ReactNode; }) => {
  return (
    <DPkContext.Provider value={props.k}>
      {props.children}
    </DPkContext.Provider>
  );
};

// hook
// -------------------------------------------------------------------------------------
export const useDPk = () => {
  const context = useContext(DPkContext);
  return context;
};