import { MapEx } from "@dp-core/utils/MapEx";

export const first = <T>(arr: T[] | Map<any, T>, filter?: (item: T) => boolean): T | undefined => {
	if (Array.isArray(arr)) {
		return filter ? arr.find(filter) : arr[0];
	}
	if (MapEx.isMap(arr)) {
		return MapEx.first(arr, filter);
	}
	return arr;
};
