import axios from 'axios'
import client, { serverLink } from '../config/createApolloClient';
import { gqlToString } from '../graphql/utils';
import { AFTER_TOKEN_CHECK } from '../hocs/users/afterTokenCheck';
import { DPLocalStorage } from '../utils/DPLocalStorage';
import { S } from '@dp-core/state3/_S';

export async function currentAccount()
{

  const userObj = DPLocalStorage.get('userInfo')
  if (userObj)
  {

    return axios
      .post(
        serverLink,
        {
          query: gqlToString(AFTER_TOKEN_CHECK),
        },
        { headers: { authorization: userObj?.token ? `Bearer ${userObj?.token}` : '' } },
      )
      .then((response) =>
      {

        let userObjFromServer
        if (response.data.data)
        {

          userObjFromServer = response.data.data.afterTokenCheck
          DPLocalStorage.set('userInfo', userObjFromServer)
          S.set("App.currentUser", userObjFromServer)


          const urlParams = new URLSearchParams(window.location.search);
          const langFromParam = urlParams.get('lang')
          if (langFromParam)
          {

            const locale = langFromParam === 'en' ? 'en-US' : 'sv-SE';
            DPLocalStorage.set('locale', locale);
            DPLocalStorage.set('app.settings.locale', `${locale}`);
            if (userObjFromServer.selectedFilters?.general)
            {

              userObjFromServer.selectedFilters.general.selectedLocale = locale

            }
            return userObjFromServer

          }
          if (userObjFromServer?.filters?.selectedLocale)
          {

            DPLocalStorage.set('locale', userObjFromServer.filters.selectedLocale);
            DPLocalStorage.set('app.settings.locale', `"${userObjFromServer.filters.selectedLocale}"`);

          }
          return userObjFromServer

        }
        DPLocalStorage.remove('userInfo')
        global.sessionStorage.clear();
        return null

      })
      .catch(() =>
      {

        DPLocalStorage.remove('userInfo')
        global.sessionStorage.clear();
        return null

      })

  }
  return null

}

export async function logout()
{

  DPLocalStorage.remove('userInfo')
  global.sessionStorage.clear();
  setTimeout(() =>
  {

    client.resetStore()

  }, 1200);
  return true

}
