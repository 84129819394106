import { debugError } from "@dp-core/error/fun/_debugError";
import { DPLang } from "@dp-core/lang/DPLang";
import { DPFormats } from "@dp-core/lang/types";
import { g_s } from "@dp-core/state3/fun/helper/_dot";
import { Sdot } from "@dp-core/state3/type";
import { ObjEx } from "@dp-core/utils/ObjEx/_ObjEx";
import { useStore } from "./_useStore";

export function getStateValue(dot: Sdot | string | undefined, format?: DPFormats): any {
	debugError(!dot, "dot is undefined");
	debugError(typeof dot === "string" && !dot.includes("."), "dot is not a valid SDot");

	// get group and state
	// --------------------
	const _dot = g_s(dot as string);
	const store = useStore.getState();
	const groups = store.groups;
	const group = groups[_dot.groupName];

	// check for props
	// ---------------
	const path = _dot.stateName;
	const val = ObjEx.getVal(group, path);

	// format
	// ------
	if (format) {
		return DPLang.format(val, format);
	}

	// return
	// ------
	return val;
}
