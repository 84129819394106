import { useStore } from "./_useStore";
import { setState } from "./setState";

// use state value
// ------------------------------------------------------------------------------------------
export function useStateValue(groupName: string, stateName: string, defVal?: any) {
	const store = useStore((store) => {
		const group = store.groups[groupName];

		if (!group || group[stateName] === undefined) {
			if (defVal !== undefined) {
				setState(`${groupName}.${stateName}`, defVal);
			}
			return defVal;
		}

		return group[stateName];
	});

	return store;
}
