export const merge = (...objs: any[]) => {
	const mergeTwo = (obj1: any, obj2: any) => {
		const result = { ...obj1 };
		for (const key in obj2) {
			if (obj2[key] && typeof obj2[key] === "object") {
				result[key] = mergeTwo(result[key] || {}, obj2[key]);
			} else {
				result[key] = obj2[key];
			}
		}
		return result;
	};

	let result = {};
	for (const obj of objs) {
		result = mergeTwo(result, obj);
	}
	return result as any;
};
