export const replaceArrayItem = <T extends Record<string, any>>(
	arr: T[],
	item: T,
	comparePropertyKey: keyof T = "_id" as keyof T,
): T[] => {
	const index = arr.findIndex((existingItem) => existingItem[comparePropertyKey] === item[comparePropertyKey]);

	if (index !== -1) {
		return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
	}

	return arr;
};
