import { debugError } from "@dp-core/error/fun/_debugError";
import { S } from "@dp-core/state3/_S";
import { g_s } from "@dp-core/state3/fun/helper/_dot";
import { Sdot } from "@dp-core/state3/type";
import { useStore } from "./_useStore";

export function getSubscribe(dot: Sdot, callback: (value: any) => void): () => void {
	const { groupName, stateName } = g_s(dot);

	debugError(!groupName, "groupName is required");
	debugError(!stateName, "stateName is required");

	const unsubscribe = useStore.subscribe(() => {
		const newValue = S.val(dot);
		callback(newValue);
	});

	return unsubscribe;
}
