import { appendToState } from "@dp-core/state3/fun/appendToState";
import { clearGroup, clearState, clearStore } from "@dp-core/state3/fun/clear";
import { getCalc } from "@dp-core/state3/fun/getCalc";
import { getCount } from "@dp-core/state3/fun/getCount";
import { getDot } from "@dp-core/state3/fun/getDot";
import { getExists } from "@dp-core/state3/fun/getExists";
import { k_dot, k_id, k_val } from "@dp-core/state3/fun/k_dot";
import { getJoin } from "@dp-core/state3/fun/getJoin";
import { getJson } from "@dp-core/state3/fun/getJson";
import { getMap } from "@dp-core/state3/fun/getMap";
import { getParent_k_dot } from "@dp-core/state3/fun/getParent_k_dot";
import { getState } from "@dp-core/state3/fun/getState";
import { getStateValue } from "@dp-core/state3/fun/getStateValue";
import { getStoreData } from "@dp-core/state3/fun/getStore";
import { getSubscribe } from "@dp-core/state3/fun/getSubscribe";
import { getSubtract } from "@dp-core/state3/fun/getSubtract";
import { getSum } from "@dp-core/state3/fun/getSum";
import { getToggleItem } from "@dp-core/state3/fun/getToggleItem";
import { keyExists } from "@dp-core/state3/fun/keyExists";
import { setNextJobStatus } from "@dp-core/state3/fun/setNextJobStatus";
import { setState } from "@dp-core/state3/fun/setState";
import { setStateTree } from "@dp-core/state3/fun/setStateTree";
import { useAndReturnVal } from "@dp-core/state3/fun/useAndReturnVal";
import { useState } from "@dp-core/state3/fun/useState";

export class S {
	static use = useState;
	static set = setState;
	static append = appendToState;
	static get = getState;
	static val = getStateValue;
	static count = getCount;
	static sum = getSum;
	static subtract = getSubtract;
	static calc = getCalc;
	static map = getMap;
	static join = getJoin;
	static exists = getExists;
	static key_exists = keyExists;
	static toggle_item = getToggleItem;
	static tree = setStateTree;
	static susbscribe = getSubscribe;
	static clear_state = clearState;
	static clear_group = clearGroup;
	static clear_store = clearStore;
	static get_store_data = getStoreData;
	static useval = useAndReturnVal;
	static k_id = k_id;
	static k_val = k_val;
	static k_dot = k_dot;
	static dot = getDot;
	static next_job_status = setNextJobStatus;
	static parent_k_dot = getParent_k_dot;
	static json = getJson;
}
