import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

export function getJson(dot: Sdot | string | undefined) {
	const val = S.val(dot);
	if (typeof val === "object") {
		return JSON.stringify(val);
	}
	return;
}
