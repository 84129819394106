import { ObjEx } from "@dp-core/utils/ObjEx/_ObjEx";

export const isArrayLike = (value: unknown): boolean => {
	if (!value || typeof value !== "object") return false;

	const keys = Object.keys(value as {});
	const _keys = ObjEx.cloneObjectProps(keys);
	const allKeysAreNumbers = _keys.every((key) => /^[\dk]+$/.test(key));
	const isNotEmpty = keys.length > 0;
	const isArray = Array.isArray(value);

	return isNotEmpty && (isArray || allKeysAreNumbers);
};
