import { Sdot } from "@dp-core/state3/type";
import { useStore } from "./_useStore";

export function getState(dot: Sdot) {
	const [groupName, stateName] = dot.split(".");
	const store = useStore.getState();
	const groups = store.groups;
	const group = groups[groupName];
	const val = group?.[stateName];
	const state = { val, set: (value: any) => store.setState(dot, value) };
	return state;
}
