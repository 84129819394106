export const pushOrReplaceArrayItem = <T extends Record<string, any>>(
	arr: T[],
	item: T,
	comparePropKeys: keyof T | (keyof T)[] = "_id" as keyof T,
): T[] => {
	const keys = Array.isArray(comparePropKeys) ? comparePropKeys : [comparePropKeys];
	const index = arr.findIndex((existingItem) => keys.every((key) => existingItem[key] === item[key]));

	if (index !== -1) {
		return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
	}

	return [...arr, item];
};
