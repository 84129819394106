import { useDPk } from "@dp-core/component/DPkProvider";
import { debugError } from "@dp-core/error/fun/_debugError";
import { S } from "@dp-core/state3/_S";

export function getParent_k_dot(stateName: string) {
	debugError(!stateName, "stateName is empty");
	const k = useDPk();

	if (!k) {
		return;
	}

	return S.dot(k, stateName);
}
