export const getVal = (obj: any, path: string): any => {
	const keys = path.split(".");

	const extract = (item: any, depth = 0): any => {
		if (depth >= keys.length || item == null) return item;

		const key = keys[depth];
		const res = Array.isArray(item) ? item.flatMap((sub) => extract(sub, depth)) : extract(item[key], depth + 1);

		return res;
	};

	const result = extract(obj);
	return result;
};
