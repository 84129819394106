import { debugError } from "@dp-core/error/fun/_debugError";
import { DPJob } from "@dp-core/job/DPJob";
import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";
import { ArrEx } from "@dp-core/utils/array/ArrEx/_ArrEx";

export const setNextJobStatus = (job: Sdot, statuses: string[]): DPJob => {
	const jobData = S.val(job) as DPJob | undefined;
	const currentStatus = jobData?.status;
	const next = ArrEx.next(statuses, currentStatus);

	debugError(!jobData, "No job provided");

	const updatedJob = {
		...jobData,
		status: next,
	};

	S.set(job, updatedJob);
	return updatedJob;
};
