import { debugError } from "@dp-core/error/fun/_debugError";
import { Sdot } from "@dp-core/state3/type";
import { StrEx } from "@dp-core/utils/StrEx";

export function getDot(dot: Sdot | string): { groupName: string; stateName: string };
export function getDot(groupName: string | undefined, stateName: string): Sdot;
export function getDot(arg1: Sdot | string | undefined, arg2?: string) {
	debugError(!arg1, "First argument is empty");

	if (typeof arg2 === "string") {
		debugError(!arg2, "stateName is empty");
		return `${arg1}.${arg2}`;
	}

	const str = StrEx.removeChars(arg1 as string, "{}");
	const [groupName, stateName] = StrEx.splitOnce(str, ".");
	return { groupName, stateName };
}
