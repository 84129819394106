export const updateArrayItem = (
	arr: any[],
	updatedItem: any,
	comparePropertyKey: string = "_id",
	where: "prepend" | "append" = "append",
): any[] => {
	const index = arr.findIndex((item) => item[comparePropertyKey] === updatedItem[comparePropertyKey]);

	if (index !== -1) {
		return [...arr.slice(0, index), { ...arr[index], ...updatedItem }, ...arr.slice(index + 1)];
	}

	return where === "append" ? [...arr, updatedItem] : [updatedItem, ...arr];
};
