export const replaceByKeys = (...objs: any[]) => {
	const replaceTwo = (obj1: any, obj2: any) => {
		const result = { ...obj1 };
		for (const key in obj2) {
			// if key exists in obj1, replace entire value without going deeper
			// ----------------------------------------------------------------
			if (key in obj1) {
				result[key] = obj2[key];
			}

			// if no direct key match, go deeper into nested objects
			// -----------------------------------------------------
			else if (obj2[key] && typeof obj2[key] === "object") {
				result[key] = replaceTwo(result[key] || {}, obj2[key]);
			}
		}

		return result;
	};

	let result = objs[0] || {};

	for (let i = 1; i < objs.length; i++) {
		result = replaceTwo(result, objs[i]);
	}

	return result as any;
};
