import { DPLang } from "@dp-core/lang/DPLang";
import { DPNumberFormats } from "@dp-core/lang/types";
import { S } from "@dp-core/state3/_S";
import { Sdot } from "@dp-core/state3/type";

export function getSubtract(
	dot: Sdot | string | undefined,
	propName: string,
	format?: DPNumberFormats,
	decimals: number = 0,
): string | number {
	const val = S.val(dot);
	let _val: number | string = -1;

	if (Array.isArray(val)) {
		_val = val.reduce((acc, item) => acc - item[propName], 0) as number;

		if (format) {
			_val = DPLang.formatNumber(_val, format, decimals) as string;
		}
	}

	return _val;
}
