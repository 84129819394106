import { useStore } from "@dp-core/state3/fun/_useStore";

export function clearStore() {
	const store = useStore.getState();
	store.clearStore();
}

export function clearGroup(groupName: string) {
	const store = useStore.getState();
	store.clearGroup(groupName);
}

export function clearState(groupName: string, stateName: string) {
	const store = useStore.getState();
	store.clearState(groupName, stateName);
}
