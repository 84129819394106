import { debugError } from "@dp-core/error/fun/_debugError";
import { formatDate } from "@dp-core/lang/format/formatDate";
import { formatNumber } from "@dp-core/lang/format/formatNumber";
import type { DPFormats } from "@dp-core/lang/types";

export const format = (val: any, format: DPFormats) => {
	let _res = undefined as string | number | undefined;

	// no format if no value
	// ---------------------
	if (!val) {
		return val;
	}

	// format value
	// ------------
	switch (format) {
		case "any-number":
		case "integer":
		case "decimal":
		case "currency-common":
		case "currency-iso":
		case "percent":
			_res = formatNumber(val, format, 0);
			break;

		case "year":
		case "month":
		case "day":
		case "hour":
		case "minute":
		case "second":
		case "millisecond":
		case "dayofweek":
		case "year-month":
		case "year-month-day":
		case "year-month-day-hour-minute":
		case "year-month-day-hour-minute-second":
			_res = formatDate(val, format);
			break;

		default:
			debugError(`Unhandled format: ${format}`);
			break;
	}

	return _res;
};
