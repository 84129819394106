import { arrayRemove } from "@dp-core/utils/array/ArrEx/arrayRemove";
import { arraysEqual, deleteArrayItem } from "@dp-core/utils/array/ArrEx/deleteArrayItem";
import { ensureArray } from "@dp-core/utils/array/ArrEx/ensureArray";
import { exists } from "@dp-core/utils/array/ArrEx/exists";
import { findNearestItemFromIndex } from "@dp-core/utils/array/ArrEx/findNearestItemFromIndex";
import { first } from "@dp-core/utils/array/ArrEx/first";
import { forEach } from "@dp-core/utils/array/ArrEx/forEach";
import { isArrayLike } from "@dp-core/utils/array/ArrEx/isArrayLike";
import { isArrayOfStrings } from "@dp-core/utils/array/ArrEx/isArrayOfStrings";
import { keyArrayToObject } from "@dp-core/utils/array/ArrEx/keyArrayToObject";
import { last } from "@dp-core/utils/array/ArrEx/last";
import { moveItemFirst } from "@dp-core/utils/array/ArrEx/moveItemFirst";
import { moveItemLast } from "@dp-core/utils/array/ArrEx/moveItemLast";
import { next } from "@dp-core/utils/array/ArrEx/next";
import { pushOrReplaceArrayItem } from "@dp-core/utils/array/ArrEx/pushOrReplaceArrayItem";
import { removeDuplicates } from "@dp-core/utils/array/ArrEx/removeDuplicates";
import { removeEmpty } from "@dp-core/utils/array/ArrEx/removeEmpty";
import { replaceArrayItem } from "@dp-core/utils/array/ArrEx/replaceArrayItem";
import { toggleItem } from "@dp-core/utils/array/ArrEx/toggleItem";
import { updateArrayItem } from "@dp-core/utils/array/ArrEx/updateArrayItem";

export class ArrEx {
	static isArrayOfStrings = isArrayOfStrings;
	static removeEmpty = removeEmpty;
	static removeDuplicates = removeDuplicates;
	static arrayRemove = arrayRemove;
	static moveItemFirst = moveItemFirst;
	static moveItemLast = moveItemLast;
	static first = first;
	static last = last;
	static findNearestItemFromIndex = findNearestItemFromIndex;
	static keyArrayToObject = keyArrayToObject;
	static updateArrayItem = updateArrayItem;
	static replaceArrayItem = replaceArrayItem;
	static pushOrReplaceArrayItem = pushOrReplaceArrayItem;
	static deleteArrayItem = deleteArrayItem;
	static arraysEqual = arraysEqual;
	static forEach = forEach;
	static isArrayLike = isArrayLike;
	static ensureArray = ensureArray;
	static toggleItem = toggleItem;
	static exists = exists;
	static next = next;
}
